@import "../../react/client/common/styles/variables.less";
@import "../../react/common/styles/imports.less";

.utils {
  &__error-background-placeholder {
    inset: 70% 0 0;
    position: absolute;
    z-index: 0;
    background-image: url("../img/pharmacy/backgrounds/lines-desktop.svg");
    background-size: cover;
    background-repeat: no-repeat;

    // Allow image to expand to edge of screen at its max size, but not shrink when smaller
    @media screen and (width <= 1440px) {
      background-size: auto;
      background-position: top center;
    }
  }
}

.store-disabled {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  &__content {
    position: relative;
    z-index: 1;
    padding: @spacing[xl];
    transform: translateY(
      -100px
    ); // Align visually on the page in the white area
  }

  &__image-container {
    width: 100%;
    height: 100%;
    max-width: 600px;
    max-height: 600px;
    margin: 0 auto;
  }

  &__image {
    width: 100%;
    height: 100%;
  }

  &__heading {
    .font(xl-5, semi-bold);

    color: @text-primary-color;
    margin-bottom: @spacing[s];
  }

  &__description {
    .font(xl, normal);

    color: @text-secondary-color;
  }
}

.browser-upgrade {
  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  &__content {
    z-index: 1;
    max-width: 800px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: @spacing[xl];
    padding: @spacing[m] @spacing[xl-7] @spacing[xl];
  }

  &__image {
    width: 100%;
    height: 100%;
    max-width: 500px;
    max-height: 500px;
  }

  &__header {
    display: flex;
    flex-direction: column;
    gap: @spacing[m];
  }

  &__title {
    .font(xl-4, semi-bold);

    color: @text-primary-color;
  }

  &__description {
    .font(l, normal);

    color: @text-secondary-color;
  }

  &__recommendations {
    display: grid;
    gap: @spacing[xl-6];
    grid-template-columns: repeat(4, 1fr);
    justify-content: center;
    margin: (@spacing[xl-7] / 2) 0;
  }

  @media @phone-and-smaller {
    &__recommendations {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__icon {
    .transition-hover(filter);

    filter: drop-shadow(2px 2px 1px fade(@box-shadow-color, 10%));
  }

  &__recommendation {
    .transition-hover(transform);

    display: flex;
    flex-direction: column;
    gap: @spacing[s];
    align-items: center;

    &:hover {
      transform: scale(1.05);

      .browser-upgrade__icon {
        filter: drop-shadow(4px 6px 2px fade(@box-shadow-color, 10%));
      }
    }
  }

  &__link {
    .font(s, normal);

    color: @text-secondary-color;
  }

  &__footer {
    .font(m, normal);

    color: @text-tertiary-color;
  }
}

// Styles for general system errors, shared between various error pages.
// This excludes any more custom formatted error pages directly tied to
// application logic.
.system-error {
  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  &__content {
    z-index: 1;
    max-width: 800px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: @spacing[xl];
    padding: @spacing[m] @spacing[xl-7] @spacing[xl];
  }

  &__image {
    width: 100%;
    height: 100%;
    max-width: 600px;
    max-height: 600px;
  }

  &__title {
    .font(xl-4, semi-bold);

    color: @text-primary-color;
  }

  &__description {
    .font(xl-2, normal);

    color: @text-primary-color;
  }

  &__next-steps {
    .font(l, normal);

    color: @text-secondary-color;
  }

  &__link {
    .link();
  }

  &__link-button {
    .font(m, normal);
    .transition-hover(border-color, background, opacity);

    border-radius: @action-border-radius;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    color: @action-primary-text-color;
    background: @action-primary-background;
    padding: @button-padding[m] 100px;

    &:not(:disabled):hover {
      background: @action-primary-hover-background;
    }

    &:not(.isLoading):disabled {
      opacity: @disabled-opacity;
    }
  }
}
