@import "../../../common/styles/variables.less";
@import "./tokens/controls.less";

// Page tokens
@page-primary-background: @white;

// Z-Index
@z-index: {
  behind: -1; // render behind any default flow
  default: 0; // Default to help with new stacking contexts
  above: 1; // For misc content that needs to be above the base page content
  dropdown: 2; // Dropdown content should render above any page content below it
  sider: 3; // Sider should overlay over most things (like shadows)
  cursors: 4; // Multiplayer cursors should overlap most content
  header: 5; // Headers should overlap most content
  sticky: 98; // Needs to overlay all main content
  drag: 99; // Needs to overlay all main content
  popover: 100; // Needs to be above sticky headers
  chat: 101; // Needs to be behind overlays
  header-dropdown: 102; // Needs to overlay on top of popovers; // Needs to be above intercom chat bubble
  overlay: 103; // Needs to overlay on top of any non-modal dialogs
  notification: 104; // Should float above core UI
  critical-dialog: 105; // should override any other display elements
  loading-bar: 190; // Needs to overlay most content
  drag-overlay: 200; // Needs to overlay everything
};

// Modal tokens
@modal-box-shadow: @box-shadow[600];
@modal-border-radius: @border-radius[l];

@size-drawer-width: {
  s: 640px;
  m: 750px;
  l: 860px;
};

@size-drawer-width-legacy: {
  s: 540px;
  m: 700px;
  l: 860px;
};

@size-tooltip-width: 300px;

// Semantic spacing
@drawer-horizontal-padding: @spacing[m];
@input-horizontal-padding: @spacing[s];
@cell-horizontal-paddding: @spacing[xs];
// Extra padding to account for absolutely positioning an icon within inputs
// Gives the icon 16px worth of width to use, which should be plenty for most icons
@input-horizontal-padding-icon: (@input-horizontal-padding * 2 + 16px);
// Dropdown arrows are consistently 10px wide
@select-padding-icon: (@input-horizontal-padding * 2 + 10px);

.icon-table() {
  font-size: 16px;
  color: @text-secondary-color;
}

// PDF tokens
@pdf-background: @grey[150];

// Widths
@content-sider-width: 400px;
@content-container-width: 800px;
@sider-width: 220px;
// this is only for the top level scrollbar on the body
@scrollbar-width: calc(100vw - 100%);

// Heights
@mobile-min-height: 500px;

// Header
@header-control-height: 36px;
@header-rail-control-height: 30px;

// Chip
@chip-border-radius: @border-radius[xl];

// Content padding
@content-horizontal-padding: @spacing[m];
@live-chat-widget-spacing: 100px;

// Utils
.circle (@diameter) {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  height: @diameter;
  width: @diameter;
}

.collapsed() {
  max-height: 0;
  overflow-y: hidden;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
}

// Misc mixins
.header-dropdown() {
  position: absolute;
  // Position top of menu at bottom of header bar
  top: (@header-control-height + @spacing[s]);
  background-color: @white;
  border: @input-border;
  border-radius: 0 0 @border-radius[s] @border-radius[s];
  box-shadow: @popover-box-shadow;
  z-index: @z-index[header-dropdown];
}

.hide-scrollbar() {
  -ms-overflow-style: none; // IE and Edge
  scrollbar-width: none; // Firefox

  &::-webkit-scrollbar {
    display: none; // Chrome
  }
}

.thin-scrollbar(@scroll-thumb-color) {
  // Helper scroll variable
  @scroll-track-color: transparent;

  // Firefox scroll styles
  scrollbar-width: thin;
  scrollbar-color: @scroll-thumb-color @scroll-track-color;

  // Chrome/Safari/edge styles
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background-color: @scroll-track-color;
  }

  &::-webkit-scrollbar-thumb {
    background-color: @scroll-thumb-color;
    border-radius: @border-radius[s];
  }
}

// Focus tokens
@focus-color: @grey[150];
@focus-primary-color: @grey[200];
@focus-shadow-size: 2px;

@focus-box-shadows: {
  basic:
    0 0 0 @focus-shadow-size @white,
    0 0 0 (@focus-shadow-size * 2) @focus-color;
  inset: inset 0 0 0 0.5px @focus-color;
  primary:
    0 0 0 @focus-shadow-size @white,
    0 0 0 (@focus-shadow-size * 2) @focus-primary-color;
};

// Default styles to apply an "outline" when focusing an element
// Applied to body *:focus in ./resets.less
// Prettier puts a space between the $ and the @ when dereferencing map variables
// which leads to syntax errors. See https://github.com/prettier/prettier/issues/14814
// for more context.

/* prettier-ignore */
.show-focus(@variant: "basic") {
  // Visible in most cases
  border-color: @focus-color;
  box-shadow: @focus-box-shadows[$@variant];
}

// Most standard case for when we want to show focus state
.show-focus-visible(@variant: "basic") {
  &:focus-visible {
    .show-focus(@variant);
  }
}

.unset-focus-visible() {
  &:focus-visible {
    box-shadow: none;
    outline: none;
  }
}

// Image tokens
.image-thumbnail() {
  // Matches the default height of the content its next to in most cases
  width: 48px;
  height: 48px;
  border-radius: @border-radius[s];
}

// Intercom tokens
@intercom-color: @action-primary-background;
@intercom-size: 68px; // 56px of the icon + 12px of white space around

// Popover tokens
@popover-background: @white;
@popover-border: 1px solid @grey[150];
@popover-box-shadow: @box-shadow[300];
@popover-border-radius: @border-radius[s];

.popover-container() {
  .transition-hover(opacity, transform);

  background: @popover-background;
  border-radius: @popover-border-radius;
  border: @popover-border;
  box-shadow: @popover-box-shadow;
}

.popover-active() {
  opacity: 1;
  pointer-events: auto;
  transform: none;
}

.popover-hidden() {
  opacity: 0;
  pointer-events: none;
  transform: perspective(1000px) rotateX(-4deg);
}

// Notification tokens
@notification-box-shadow: @box-shadow[600];

// Card tokens
@card-box-shadow: @box-shadow[300];
@card-box-shadow-hover: @box-shadow[400];

// Table tokens
@table-active-icon-color: @accent[500];
@table-inactive-icon-color: @grey[200];

// Chip tokens
@chip-height: {
  s: 20px;
  m: 24px;
};

// Table tokens
@table-background: @white;
@table-border-radius: @border-radius[s];
@table-header-background: @grey[75];
@table-border: @divider-border;

// Label tokens
@label-font-size: @font-sizes[xs];
@label-spacing: @spacing[xs-2];

// Button tokens
@button-disabled-opacity: 0.5;

// Pims tokens
@sider-spacing: @spacing[m];
@medical-record-spacing: @spacing[xs];

// Icon tokens
@interactive-icon-hover-size: 2em;

// Storefront theme preview
@storefront-theme-preview-height: 280px; // hardcoded to show the right header content

// Hovering
@hover-background: fadecolor(@action-secondary-hover-background, 10%);

// Patient timeline
@patient-timeline-info-width: 170px;
